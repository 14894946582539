<template>
	<div class="p-3">
		<b-form @submit.prevent="updateTicketNumber">
			<div class="row no-gutters d-flex justify-content-end mb-3">
				<div class="col">
					<input
						ref="ticketNumber"
						v-model="searchTicketNumber"
						:placeholder="translate('enter_ticket_number')"
						type="text"
						class="form-control">
				</div>
				<div class="pl-1 col-auto">
					<button
						type="submit"
						class="btn btn-primary w-100">
						<template v-if="!loading">
							{{ translate('search') }}
						</template>
						<template v-else>
							<em class="fa fa-fw fa-spinner fa-pulse" />
						</template>
					</button>
				</div>
			</div>
		</b-form>
		<div class="row">
			<div class="col-12 col-md-7 col-lg-8 mb-3">
				<div class="row">
					<div class="col-12 d-flex justify-content-end" />
				</div>
				<b-card
					no-body
					class="h-100">
					<template
						v-slot:header>
						<div class="col-auto pl-1 pt-1 float-left">
							<label class="h5 pointer">{{ translate('confirmed_tickets') }}</label>
						</div>
						<div class="float-left">
							<b-button
								v-b-tooltip.hover
								:title="translate('refresh')"
								variant="primary"
								size="sm"
								@click="getTickets">
								<em
									class="fas fa-sync"
									:class="loading ? 'fa-spin' : ''" />
							</b-button>
						</div>
					</template>
					<b-card-body
						class="p-0"
						style="height: 25em; overflow-y: auto">
						<template
							v-for="(ticket) in data">
							<CheckInItem
								:key="ticket.id"
								:ticket="ticket"
								:selected-ticket.sync="selected"
								:selected-id="selected ? selected['id'] : ''" />
						</template>
						<div
							v-if="!hasData"
							class="d-flex justify-content-center align-items-center h-100">
							<is-loading
								:loading="loading"
								:has-data="hasData"
								:loading-label="translate('loading')"
								:no-data-label="translate('data_not_found')" />
						</div>
					</b-card-body>
				</b-card>
			</div>
			<ticket-information
				:ticket="selected"
				@cancelConfirmation="confirmAlert" />
		</div>
	</div>
</template>
<script>
import CheckIn from '@/views/Orders/mixins/CheckIn';

export default {
	name: 'EventTicketsCheckedIn',
	mixins: [CheckIn],
	mounted() {
		this.getTickets();
	},
	methods: {
		getTickets(ticketNumber) {
			const options = { confirmed: 1 };
			if (typeof ticketNumber === 'string' && ticketNumber) {
				options.ticket_number = ticketNumber;
			}
			this.eventTickets.getAllTickets(options).finally(() => {
				this.selected = null;
				this.updateSummary();
			});
		},
		updateTicketNumber() {
			this.getTickets(this.searchTicketNumber);
			this.searchTicketNumber = '';
		},
	},
};
</script>
